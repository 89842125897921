import { useMatch } from '@tanstack/react-location';
import { useMutation, useQueryClient } from 'react-query';
import {
  ConfirmPlanData,
  PaymentPlanConfirmationFrom,
  PaymentPlanConfirmationStatus,
  PaymentPlanStatus,
  confirmPaymentPlan,
} from '@frontend/api-payment-plans';
import { usePaymentPlanInfo } from './use-payment-plan-info';

export const useConfirmPlan = () => {
  const queryClient = useQueryClient();
  const { params } = useMatch();
  const { planId } = params;
  const { paymentPlan } = usePaymentPlanInfo();

  const {
    mutate,
    error: confirmationError,
    isLoading: confirmationLoading,
  } = useMutation({
    mutationFn: (confirmationData: ConfirmPlanData) => {
      const confirmedAt = new Date().toISOString();
      return confirmPaymentPlan({
        id: planId,
        confirmedAt,
        confirmationFrom: PaymentPlanConfirmationFrom.Customer,
        ...confirmationData,
      });
    },
    onSuccess: (_, { confirmationStatus = PaymentPlanConfirmationStatus.Pending }) => {
      queryClient.setQueryData([planId], {
        ...paymentPlan,
        confirmationStatus,
        confirmationDetails: { ...paymentPlan?.confirmationDetails, confirmationStatus },
      });
    },
  });

  const agreePlan = () => {
    mutate({ expireLink: true, confirmationStatus: PaymentPlanConfirmationStatus.Accept });
  };

  const denyPlan = () => {
    mutate({ expireLink: true, confirmationStatus: PaymentPlanConfirmationStatus.Deny });
  };

  const undoDenial = () => {
    mutate({ expireLink: false, status: PaymentPlanStatus.Paused });
  };

  return {
    agreePlan,
    denyPlan,
    undoDenial,
    confirmationError,
    confirmationLoading,
    onlineBillPayLink: 'https://pay.weavedev.net/97f98309',
  };
};
