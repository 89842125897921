import { ReactLocation } from '@tanstack/react-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import env from '@frontend/env';
import { ThemeProvider, AlertsProvider } from '@frontend/design-system';
import { Main } from './components/main';
import { RoutesProvider } from './providers/routes.provider';
import { unregister } from './utils/registerServiceWorker';

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

// This is used in auth and routing
const location = new ReactLocation({});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function Root() {
  console.log('version: ', env.VERSION);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider includeEmotionTheme skipAnimation={!!window?.Cypress}>
        <AlertsProvider>
          <RoutesProvider>
            <Main location={location} />
          </RoutesProvider>
        </AlertsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

// For now, we are opting out of using the service worker
// We could change this for production later if we want the improved performance
unregister();
